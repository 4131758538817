//
// Sidebar
// --------------------------------------------------


// Sidebar toggle button

.sidebar-toggle {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  z-index: $zindex-btn-scroll-top;
}


// Adding extra bottom spacing to accomodate sidebar toggle

.is-sidebar {
  .footer {
    padding-bottom: 2.25rem;
  }
  .sidebar-toggle {
    display: block;
  }
  @include media-breakpoint-down(lg) {
    .btn-scroll-top {
      bottom: $spacer + 2.25rem;
    }
  }
}


@include media-breakpoint-up(lg) {
  .sidebar-enabled {
    position: relative;
    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 40%;
      height: 100%;
      background-color: $sidebar-bg;
      content: '';
    }
    .content {
      position: relative;
      padding-left: 2.5rem;
      background-color: $body-bg;
      z-index: 2;
    }
    &.sidebar-end {
      .content {
        padding: {
          left: $grid-gutter-width * .5;
          right: 2.5rem;
        }
      }
      &::before {
        right: 0;
        left: auto;
      }
    }
  }
  .sidebar {
    position: relative;
    background-color: $sidebar-bg;
    z-index: 5;
  }

  .sidebar-toggle { display: none; }
  .is-sidebar {
    .footer { padding-bottom: 0; }
    .sidebar-toggle { display: none; }
  }
}
