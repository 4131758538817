//
// Animations
// --------------------------------------------------


.from-top, .from-bottom,
.from-left, .from-right,
.scale-up, .scale-down,
.fade-in {
  transition: all .4s .3s ease-in-out;
  opacity: 0;
  backface-visibility: hidden;
}
.from-top {
  transform: translateY(-2.25rem);
}
.from-bottom {
  transform: translateY(2.25rem);
}
.from-left {
  transform: translateX(-2.25rem);
}
.from-right {
  transform: translateX(2.25rem);
}
.scale-up {
  transform: scale(0.85);
}
.scale-down {
  transform: scale(1.15);
}
.delay-1 {
  transition-delay: .5s;
}
.delay-2 {
  transition-delay: .7s;
}
.delay-3 {
  transition-delay: .9s;
}
.delay-4 {
  transition-delay: 1.1s;
}

.tns-slide-active {
  .from-top, .from-bottom {
    transform: translateY(0);
    opacity: 1;
  }
  .from-left, .from-right {
    transform: translateX(0);
    opacity: 1;
  }
  .scale-up, .scale-down {
    transform: scale(1);
    opacity: 1;
  }
  .fade-in { opacity: 1; }
}
